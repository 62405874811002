import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { UpdateParticipantStepStatusPayload } from './ParticipantStepsContext';
import { getUrlParams } from '../Location/locationProviderPropsMap';
import { ParticipantStep } from '../../types/v3Types';

export const updateParticipantStepStatus = (
  flowAPI: ControllerFlowAPI,
  payload: UpdateParticipantStepStatusPayload,
): void => {
  const { stepId, steps, sections, replace, quizSubmission, transitions } =
    payload;
  const checkStep = (step) => {
    if (step.id === stepId) {
      if (transitions || replace) {
        step.transitions = transitions;
      }

      if (quizSubmission || replace) {
        step.quizSubmission = quizSubmission;
      }
    }
  };

  if (sections?.length) {
    sections.forEach((section) => {
      (section.steps || []).forEach(checkStep);
    });

    flowAPI.controllerConfig.setProps({
      listParticipantSections: sections,
    });
  } else if (steps?.length) {
    steps.forEach(checkStep);

    flowAPI.controllerConfig.setProps({
      participantSteps: { steps },
    });
  } else {
    console.error("Can't update participant step status.");
  }
};

async function getSelectedStep(
  loadedSteps: ParticipantStep[],
  flowAPI: ControllerFlowAPI,
) {
  const { navigationId } = getUrlParams(flowAPI);
  return loadedSteps.find((step) => step.id === navigationId);
}

export async function getStepNavigationInfo(
  flowAPI,
  loadedSteps: ParticipantStep[],
): Promise<{
  selectedStep?: ParticipantStep;
}> {
  const { navigationType } = getUrlParams(flowAPI);
  if (navigationType === 'step') {
    const selectedStep = await getSelectedStep(loadedSteps, flowAPI);
    return { selectedStep };
  }
  return {
    selectedStep: undefined,
  };
}
