import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { ResolvedStep } from '@wix/ambassador-online-programs-v3-resolved-step/types';
import { listResolvedSteps } from '@wix/ambassador-online-programs-v3-resolved-step/http';
import { request } from '../../../../services/request';
import { handleError } from '../../../ErrorHandler/errorHandlerPropsMap';

export async function requestResolvedSteps(
  participantId: string,
  flowAPI: ControllerFlowAPI,
): Promise<ResolvedStep[]> {
  if (!participantId) {
    return [];
  }
  try {
    return (
      await request({
        flowAPI,
        options: listResolvedSteps({
          participantId,
        }),
        errorOptions: {
          errorCodesMap: {
            applicationError: {},
          },
        },
      })
    )?.data?.resolvedSteps;
  } catch (e) {
    const error = flowAPI.errorHandler.getResolvedError(e);
    handleError({
      error,
      resolvedError: error,
      context: 'requestResolvedSteps',
      preventErrorMonitorReport: false,
    });
    return [];
  }
}
