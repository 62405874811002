import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { userProviderPropsMap } from '../../User/userProviderPropsMap';
import { isUserJoinedAlreadyWithoutSuspended } from '../../User/helpers/userTypeHandlers';
import { getPaidPlansInitialData } from './data';

export const initParticipantPagePaidPlansData = async (
  flowAPI: ControllerFlowAPI,
) => {
  const userType = (await userProviderPropsMap(flowAPI)).userType;
  if (isUserJoinedAlreadyWithoutSuspended(userType)) {
    return {
      userPaidPlans: [],
      eligiblePlans: [],
    };
  }
  return getPaidPlansInitialData(flowAPI);
};
