import {
  ControllerFlowAPI,
  ControllerParams,
  CreateControllerFn,
} from '@wix/yoshi-flow-editor';
import { createEventHandler } from '@wix/tpa-settings';
import { ScreenNames } from '../../contexts/main/biInterfaces';
import {
  arrayToObjectAsync,
  getBaseProvidersData,
} from '../../contexts/main/getBaseProvidersData';
import { ISettingsEvents, RolesState } from './Widget/settingsEvents';
import { ButtonState } from '../ChallengesList/Widget/settingsEvents';
import { ISidebarMobileScreenFromSettings } from './Settings.mobile/tabs/Design/DesignSettings';
import { locationProviderPropsMap } from '../../contexts/Location/locationProviderPropsMap';
import { userProviderPropsMap } from '../../contexts/User/userProviderPropsMap';
import { participantSectionsPropsMap } from '../../contexts/ParticipantSections/ParticipantSectionsPropsMap';
import { resolveStepDataProviderPropsMap } from '../../contexts/ResolveStep/ResolveStepDataProviderPropsMap';
import { socialGroupsPropsMap } from '../../contexts/SocialGroups/SocialGroupsPropsMap';
import { LayoutSelectedTab } from '../ChallengesPage/Settings/tabs/Design/components/OverviewScheduleSwitcher/constants';
import { getBadgesInitialData } from '../../contexts/storage-contexts/Badges';
import {
  getChallengeInitialData,
  resolveProgramId,
} from '../../contexts/storage-contexts/Challenge';
import { Referrer } from '../../contexts/storage/referrer';
import { CHALLENGE_PAGE_ID } from '../../editor/app-config';
import { isForcedPreviewParticipant } from '../../selectors/isForcedPreview';
import { isUserJoinedAlready } from '../../contexts/User/helpers/userTypeHandlers';
import { renderSeoMetatagsPage } from '../../services/SeoMetatagsPage';
import { getVideoInitialData } from '../../contexts/storage-contexts/Video';
import { initParticipantPagePaidPlansData } from '../../contexts/storage-contexts/PaidPlans/initParticipantPagePaidPlanData';
import { getProgramSlug } from '../../selectors/getProgramSlug';
import { quizPropsMap } from '../../contexts/Quiz/QuizPropsMap';
import { getParticipantIdFromInitialProps } from '../../selectors/participant/getParticipantId';
import { biButtonClick } from '../../services/biHelpers';
import {
  isLoggedInUser,
  promptLoginPage,
} from '../../contexts/User/helpers/userContextHelpers';
import { isBMPreview } from '../../selectors/isBMPreview';
import { setControllerFlowApi } from '../../services/controllerProps/controllerProps';

export const initChallengePage = async (flowAPI: ControllerFlowAPI) => {
  setControllerFlowApi(flowAPI);
  const { setProps } = flowAPI.controllerConfig;

  try {
    const initialProps = {
      ...(await arrayToObjectAsync([
        getBaseProvidersData({
          flowAPI,
        }),
        userProviderPropsMap(flowAPI),
        getChallengeInitialData(flowAPI, Referrer.PARTICIPANT_PAGE),
        getBadgesInitialData(),
        participantSectionsPropsMap(flowAPI),
        socialGroupsPropsMap(flowAPI),
        initParticipantPagePaidPlansData(flowAPI),
        resolveStepDataProviderPropsMap(flowAPI),
        getVideoInitialData(flowAPI),
        resolveProgramId(flowAPI),
        quizPropsMap(flowAPI),
      ])),
    };

    const resolvedProgramId = initialProps?.programId;

    if (
      flowAPI.experiments.enabled(
        'specs.programs.OOIParticipantPageSeoMetaTags',
      )
    ) {
      renderSeoMetatagsPage(flowAPI, null, 'PROTECTED_PAGE');
      flowAPI.controllerConfig.wixCodeApi.seo.setTitle(
        initialProps.challengeData?.challenge?.settings?.description?.title ||
          'Participant Page',
      );
    }

    const programId = initialProps.challengeData?.challenge?.id;
    flowAPI.bi.updateDefaults({
      origin: flowAPI.controllerConfig.wixCodeApi.window.viewMode,
      _uuid: flowAPI.controllerConfig.platformAPIs.bi.ownerId,
      screenName: ScreenNames.ChallengePageForParticipant,
      participantId: getParticipantIdFromInitialProps(initialProps),
      challengeId: programId,
    });

    // If visitor of the program goes to the participant page, he should be redirected.
    if (
      isUserJoinedAlready(initialProps.userType) ||
      isForcedPreviewParticipant(initialProps.query) ||
      !flowAPI.environment.isViewer
    ) {
      setProps({ ...initialProps });
    } else {
      setProps({
        isForcedBlankPage: true,
      });
      void biButtonClick(flowAPI, 'REDIRECT_TO_PROGRAM_PAGE');
      initialProps.goToPage({
        pageId: CHALLENGE_PAGE_ID,
        challengeId:
          getProgramSlug(initialProps?.challengeData?.challenge) ||
          resolvedProgramId,
        queryParams: resolvedProgramId
          ? {
              programId: resolvedProgramId,
            }
          : undefined,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

async function goToVisitorPage(flowAPI: ControllerFlowAPI) {
  const data = await getChallengeInitialData(
    flowAPI,
    Referrer.PARTICIPANT_PAGE,
  );
  const programId = data?.challengeData?.challenge?.id;
  if (!programId) {
    return;
  }
  locationProviderPropsMap(flowAPI).goToPage({
    pageId: CHALLENGE_PAGE_ID,
    challengeId: getProgramSlug(data.challengeData.challenge),
    queryParams: programId
      ? {
          programId,
        }
      : undefined,
  });
}

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
}: ControllerParams) => {
  const { setProps } = flowAPI.controllerConfig;
  const publicData = controllerConfig.config.publicData.COMPONENT || {};
  const settingsEventsHandler = createEventHandler<ISettingsEvents>(publicData);

  settingsEventsHandler.on('buttonState', (value) => {
    setProps({
      buttonState: value,
    });
  });

  settingsEventsHandler.on('LayoutSelectedTab', (value) => {
    setProps({
      shownTab: value,
    });
  });

  settingsEventsHandler.on('sidebarLayoutMobileScreen', (value) => {
    const newProps: any = {};

    newProps.sidebarLayoutMobileScreen = value;

    if (value === ISidebarMobileScreenFromSettings.Overview) {
      newProps.shownTab = LayoutSelectedTab.Overview;
    } else if (value === ISidebarMobileScreenFromSettings.Schedule) {
      newProps.shownTab = LayoutSelectedTab.Schedule;
    }

    setProps(newProps);
  });

  settingsEventsHandler.onReset(() => {
    setProps({
      buttonState: ButtonState.Default,
      pageRole: RolesState.Visitor,
      sidebarLayoutMobileScreen: null,
    });
  });

  return {
    async pageReady() {
      if (
        isLoggedInUser(flowAPI) ||
        !flowAPI.environment.isViewer ||
        isBMPreview(flowAPI)
      ) {
        await initChallengePage(flowAPI);
      } else {
        flowAPI.controllerConfig.setProps({});
        if (!flowAPI.environment.isSSR) {
          promptLoginPage(flowAPI)
            .then(async () => {
              void biButtonClick(flowAPI, 'USER_LOGGED_IN');
              await initChallengePage(flowAPI);
            })
            .catch(async () => {
              void biButtonClick(flowAPI, 'PARTICIPANT_LOGIN_CANCELED');
              await goToVisitorPage(flowAPI);
            });
        }
      }

      // fix of: https://jira.wixpress.com/browse/OPROG-3815
      // do not need to clear cache, it was done for visitor page
      // flowAPI.controllerConfig.wixCodeApi?.location?.onChange(
      //   async (...args) => {
      //     clearProvidersCache();
      //     await initChallengePage(flowAPI);
      //   },
      // );
    },
    updateConfig($w, config) {
      settingsEventsHandler.notify(config.publicData.COMPONENT || {});
    },
  };
};

export default createController;
