import { Section } from '@wix/ambassador-online-programs-v3-section/types';
import { getFormattedDate } from '../components/ParticipantPage/Widget/components/StepsListAsTiles/GetFormattedDate';
import { addDays } from 'date-fns';

export function assignSectionDateTitleForScheduledProgram(opts: {
  isScheduled: boolean;
  language: string;
  section: Section;
  participantStartDay: Date;
}): Section {
  if (!opts.isScheduled) {
    return opts.section;
  }
  return {
    ...opts.section,
    description: {
      ...opts.section.description,
      title: getFormattedDate(
        opts.language,
        addDays(opts.participantStartDay, opts.section.delayInDays),
      ),
    },
  };
}
