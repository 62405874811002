import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getStep } from '@wix/ambassador-online-programs-v3-step/http';
import { request } from '../../../../services/request';

import {
  GetStepRequest,
  Step,
} from '@wix/ambassador-online-programs-v3-step/types';

export async function requestStepV3(
  req: GetStepRequest,
  flowAPI: ControllerFlowAPI,
): Promise<Step> {
  return (
    await request({
      flowAPI,
      options: getStep(req),
      errorOptions: {
        errorCodesMap: {
          applicationError: {},
        },
      },
    })
  )?.data?.step;
}
