import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getDetails } from '../../selectors/step';
import { DescriptionFieldSet } from '@wix/ambassador-challenges-v1-challenge/types';
import { requestStepV3 } from '../storage-contexts/Challenge/api/requestStepV3';
import { Step } from '../../types/v3Types';
import { handleError } from '../ErrorHandler/errorHandlerPropsMap';

export type StepDescriptionMap = { [participantStepId: string]: string };

export const stepDescriptionMap: StepDescriptionMap = {};

export const updateStepDescriptionMap = (
  sourceStep: Step,
  participantStepId: string,
) => {
  stepDescriptionMap[participantStepId] = getDetails(sourceStep);
};

export async function requestParticipantStep(
  stepId: string,
  flowAPI: ControllerFlowAPI,
): Promise<void> {
  try {
    const step = await requestStepV3(
      { stepId, descriptionFieldSet: DescriptionFieldSet.EXTENDED },
      flowAPI,
    );
    updateStepDescriptionMap(step, stepId);

    flowAPI.controllerConfig.setProps({
      stepDescriptionMap,
    });
  } catch (err) {
    const resolvedError = flowAPI.errorHandler.getResolvedError(err);
    handleError({
      error: err,
      resolvedError,
      context: 'requestParticipantStep',
      preventErrorMonitorReport: false,
    });
  }
}
