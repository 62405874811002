import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { handleError } from '../../ErrorHandler/errorHandlerPropsMap';
import { getInstance } from '../../../services/instance';
import { IVideoContext } from './interfaces';
import { GetVideoRequest } from '@wix/challenges-web-video-serverless/types';
import { defaultErrorOptions } from '../../../utils/defaultErrorOptions';

export const getVideoInitialData = (flowAPI: ControllerFlowAPI) => {
  return {
    requestVideoData: async (stepId: string) => {
      const instanceFromUrl = getInstance(flowAPI);

      flowAPI.controllerConfig.setProps({
        isVideoRequestInProgress: true,
      });

      try {
        const params: GetVideoRequest = { stepId };
        const videoDataResponse = await flowAPI.errorHandler.withErrorHandler(
          () =>
            flowAPI.httpClient.get(
              `/_serverless/challenges-web-video-serverless/video/${stepId}`,
              {
                params,
                headers: {
                  Authorization: instanceFromUrl,
                },
              },
            ),
          defaultErrorOptions,
        );

        if (videoDataResponse.status === 200) {
          flowAPI.controllerConfig.setProps({
            videoData: videoDataResponse.data,
            isVideoRequestInProgress: false,
            videoDataRequestError: null,
          });
        } else {
          const error = new Error("Can't get video data.");

          flowAPI.controllerConfig.setProps({
            videoData: null,
            isVideoRequestInProgress: false,
            videoDataRequestError: error,
          });

          handleError({
            error,
            context: 'requestVideoData.requestError',
            muteError: true,
          });
        }
      } catch (error) {
        const resolvedError = flowAPI.errorHandler.getResolvedError(error);
        flowAPI.controllerConfig.setProps({
          videoData: null,
          isVideoRequestInProgress: false,
          videoDataRequestError: new Error(error),
        });

        handleError({
          error,
          resolvedError,
          context: 'requestVideoData.generalError',
          muteError: true,
        });
      }
    },
    videoData: null,
    isVideoRequestInProgress: false,
    videoDataRequestError: null,
    requestHLSStreamURL: async (stepId: string) => {
      const instanceFromUrl = getInstance(flowAPI);

      flowAPI.controllerConfig.setProps({
        isHLSStreamURLRequestInProgress: true,
      });

      try {
        const params = { stepId };
        const HLSStreamURLResponse =
          await flowAPI.errorHandler.withErrorHandler(
            () =>
              flowAPI.httpClient.get(
                `/_serverless/challenges-web-video-serverless/generate-stream-url/${stepId}`,
                {
                  params,
                  headers: {
                    Authorization: instanceFromUrl,
                  },
                },
              ),
            defaultErrorOptions,
          );

        if (HLSStreamURLResponse.status === 200) {
          flowAPI.controllerConfig.setProps({
            HLSStreamURL: HLSStreamURLResponse.data,
            isHLSStreamURLRequestInProgress: false,
            HLSStreamURLRequestError: null,
          });
        } else {
          const error = new Error("Can't get video view url.");

          flowAPI.controllerConfig.setProps({
            HLSStreamURL: null,
            isHLSStreamURLRequestInProgress: false,
            HLSStreamURLRequestError: error,
          });

          handleError({
            error,
            context: 'requestHLSStreamURL.requestError',
            muteError: true,
          });
        }
      } catch (error) {
        flowAPI.controllerConfig.setProps({
          HLSStreamURL: null,
          isHLSStreamURLRequestInProgress: false,
          HLSStreamURLRequestError: new Error(error),
        });

        const resolvedError = flowAPI.errorHandler.getResolvedError(error);
        handleError({
          error,
          resolvedError,
          context: 'requestHLSStreamURL.generalError',
          muteError: true,
        });
      }
    },
    HLSStreamURL: null,
    isHLSStreamURLRequestInProgress: false,
    HLSStreamURLRequestError: null,
    resetVideoState: () => {
      flowAPI.controllerConfig.setProps({
        videoData: null,
        isVideoRequestInProgress: false,
        videoDataRequestError: null,
        HLSStreamURL: null,
        isHLSStreamURLRequestInProgress: false,
        HLSStreamURLRequestError: null,
      });
    },
  } as IVideoContext;
};
