import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { IParticipantSectionsContext } from './ParticipantSectionsContext';
import { getSectionNavigationInfo } from './helpers';
import {
  getStepNavigationInfo,
  updateParticipantStepStatus,
} from '../ParticipantStepsDataProvider/helpers';
import memoize from 'lodash/memoize';
import { getChallengeData } from '../storage-contexts/Challenge';
import {
  addOnLoginHandler,
  IOnLoginHandlerPriority,
} from '../GeneralDataProvider/helpers/onLogin';
import { getParticipantSectionsV3 } from './GetParticipantSectionsV3';
import {
  requestParticipantStep,
  stepDescriptionMap,
  updateStepDescriptionMap,
} from './requestParticipantStep';
import { requestParticipantSection } from './requestParticipantSection';
import { getDetails } from '../../selectors/step';

const updateParticipantsSections = async (flowAPI: ControllerFlowAPI) => {
  const challengeData = await getChallengeData(flowAPI);
  const { sections, steps } = await getParticipantSectionsV3(
    challengeData.challenge,
    flowAPI,
  );

  flowAPI.controllerConfig.setProps({
    listParticipantSections: sections,
    participantSteps: { steps },
  });
};

const handleSectionsAfterLogin = () => {
  addOnLoginHandler({
    priority: IOnLoginHandlerPriority.SECONDARY,
    handler: async (flowAPI: ControllerFlowAPI) => {
      const challengeData = await getChallengeData(flowAPI);
      const { sections, steps } = await getParticipantSectionsV3(
        challengeData?.challenge,
        flowAPI,
      );
      return flowAPI.controllerConfig.setProps({
        listParticipantSections: sections,
        participantSteps: { steps },
        isListParticipantSectionsRequestInProgress: false,
      });
    },
  });
};

export const participantSectionsPropsMap = memoize(async function (
  flowAPI: ControllerFlowAPI,
): Promise<IParticipantSectionsContext> {
  let sections: IParticipantSectionsContext['listParticipantSections'];
  let steps: IParticipantSectionsContext['participantSteps']['steps'];

  const challengeData = await getChallengeData(flowAPI);
  const challenge = challengeData?.challenge;
  ({ sections, steps } = await getParticipantSectionsV3(challenge, flowAPI));
  const [selectedSection, { selectedStep }] = await Promise.all([
    getSectionNavigationInfo(flowAPI, sections),
    getStepNavigationInfo(flowAPI, steps),
  ]);

  void handleSectionsAfterLogin();

  if (getDetails(selectedStep?.source)) {
    updateStepDescriptionMap(selectedStep.source, selectedStep.id);
  }

  return {
    selectedStep,
    stepDescriptionMap,
    selectedSection,
    participantSteps: { steps },
    listParticipantSections: sections,
    isListParticipantSectionsRequestInProgress: false,
    isParticipantStepsLoading: false,
    updateParticipantSections: () => updateParticipantsSections(flowAPI),
    updateParticipantSteps: async () => {
      //todo implement or use updateParticipantsSections
    },
    updateParticipantStepStatus: async (payload) =>
      updateParticipantStepStatus(flowAPI, payload),
    requestParticipantSectionStatus: 'INITIAL',
    requestParticipantSection: async (sectionId, lSections) =>
      requestParticipantSection(sectionId, lSections, flowAPI),
    requestParticipantStep: async (stepId) =>
      requestParticipantStep(stepId, flowAPI),
  };
});
