import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { isForcedPreviewParticipant } from '../../selectors/isForcedPreview';
import { getUrlParams } from '../Location/locationProviderPropsMap';

export const isRequiredOwnerData = (flowAPI: ControllerFlowAPI) => {
  const { isEditor, isPreview } = flowAPI.environment;

  return (
    isEditor ||
    isPreview ||
    isForcedPreviewParticipant(
      flowAPI?.controllerConfig?.wixCodeApi?.location?.query,
    )
  );
};

export async function getSectionNavigationInfo<S extends { id: string }>(
  flowAPI: ControllerFlowAPI,
  sections: S[],
): Promise<S | undefined> {
  const urlParams = getUrlParams(flowAPI);
  if (urlParams.navigationType === 'section') {
    return sections.find((s) => s.id === urlParams.navigationId);
  }
}
